import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import * as XLSX from "xlsx/xlsx.mjs";

import gtag from "app/ga";

const Split = ({ config }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [rows, setRows] = useState([]);

  const summariseFiles = async (files) => {
    const newRows = [];
    let i = rows.length;
    files.forEach(async (e) => {
      newRows.push({
        idx: i,
        file: e,
        filename: e.name,
        filename_only: e.name.split(".").slice(0, -1).join("."),
        extension: e.name.split(".").at(-1),
        isProcessing: true,
      });
      i += 1;
    });
    setRows([...rows, ...newRows]);
    setFiles([]);
    gtag("event", "freetools_result");
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const setNewRows = (currentRows, newRow, idx) => {
    let newRows = [...currentRows];
    newRows[idx] = newRow;
    newRows = newRows.sort((a, b) => a.idx - b.idx);

    let i = -1;
    newRows = newRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    setRows(newRows);
  };

  const header_key = "_5c9042c23c91a0f34b6_codex_freetools";

  const splitDataXlsx = (wb, newRow) => {
    const groupData = {};

    let columns = config.columns ? config.columns.split(",") : [];
    const first_row = config.first_row || 2;

    columns = columns.map((col) => XLSX.utils.decode_col(col));

    const keys = [];

    newRow.sheetNames.forEach((sn) => {
      if (
        config.in_scope_sheet.includes(sn) ||
        config.in_scope_sheet.length === 0
      ) {
        groupData[sn] = {};
        let data = XLSX.utils.sheet_to_json(wb.Sheets[sn], { header: 1 });
        groupData[sn][header_key] = [...data.slice(0, first_row - 1)];

        data.slice(first_row - 1).forEach((e) => {
          const key = columns
            .map((k) => {
              const v = e.at(parseInt(k));
              if (v === "" || typeof v === "undefined") {
                return "$blank";
              } else {
                return v;
              }
            })
            .join(";");

          if (!keys.includes(key)) {
            keys.push(key);
          }

          if (groupData[sn][key]) {
            groupData[sn][key] = [...groupData[sn][key], e];
          } else {
            groupData[sn][key] = [e];
          }
        });
      }
    });
    keys.forEach((k) => {
      const workbook = XLSX.utils.book_new();

      Object.entries(groupData).forEach(([k2, v2]) => {
        let worksheet = null;

        const aoa = [...v2[header_key], ...(v2[k] || [])];

        worksheet = XLSX.utils.aoa_to_sheet(aoa);

        XLSX.utils.book_append_sheet(workbook, worksheet, k2);
      });

      const save_filename = config.incl_original
        ? `${newRow.filename_only}(${k})`
        : k;
      XLSX.writeFile(workbook, `${save_filename}.xlsx`);
    });
  };

  const processXlsx = async (rows, idx) => {
    let newRow = { ...rows[idx] };
    if (newRow.extension === "xlsx") {
      const data = await newRow.file.arrayBuffer();
      const wb = XLSX.read(data, {
        cellNF: true,
        cellStyles: true,
        cellDates: true,
      });
      newRow = {
        ...rows[idx],
        sheetNames: wb.SheetNames,
        noOfSheet: wb.SheetNames.length,
        workbook: wb,
        isProcessing: false,
      };
      splitDataXlsx(wb, newRow);
      setNewRows(rows, newRow, idx);
    } else {
      newRow = {
        ...rows[idx],
        noOfSheet: t("Invalid file type"),
        isProcessing: false,
      };
      setNewRows(rows, newRow, idx);
    }
  };

  useEffect(() => {
    if (rows.filter((e) => e.isProcessing).length > 0) {
      if (!isProcessing) {
        setIsProcessing(true);
      }
      const idx = rows.findIndex((e) => e.isProcessing);
      processXlsx(rows, idx);
    } else {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  }, [rows]);

  return (
    <Fragment>
      {!isProcessing ? (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      ) : (
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SuiBox pt={3}>
            <CircularProgress color="info" />
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption">
              {t("Processing...")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
    </Fragment>
  );
};

export default Split;
