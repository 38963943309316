const bahttext = (value) => {
  if (typeof value !== "number") {
    throw "Value must be number";
  }

  value = value.toFixed(2);
  const value_str = value.split(".");
  const [baht_str, satang_str] = value_str;

  const _number = [
    "",
    "หนึ่ง",
    "สอง",
    "สาม",
    "สี่",
    "ห้า",
    "หก",
    "เจ็ด",
    "แปด",
    "เก้า",
  ];
  const _digit = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน"];
  const _digit10 = [
    "",
    "สิบ",
    "ยี่สิบ",
    "สามสิบ",
    "สี่สิบ",
    "ห้าสิบ",
    "หกสิบ",
    "เจ็ดสิบ",
    "แปดสิบ",
    "เก้าสิบ",
  ];

  const _len = baht_str.length;

  let _bahttext = "";

  if (value == 0) {
    _bahttext = "ศูนย์";
  }

  for (let i = 0; i < _len; i++) {
    let _j = (15 + _len + 2 - i) % 6;
    let _d = parseInt(baht_str.at(i));
    let _word = "";
    if (_d != 0) {
      if (_j == 1) {
        _word = _digit10[_d];
      } else {
        _word = _number[_d] + _digit[parseInt(_j)];
      }
    }
    _bahttext += _word;
    if (_j == 0 && i + 1 != _len) {
      _bahttext += "ล้าน";
    }
  }

  _bahttext = _bahttext.replace("หนึ่งล้าน", "เอ็ดล้าน");

  if (baht_str.slice(0, 1) == "1") {
    _bahttext = _bahttext.replace("เอ็ดล้าน", "หนึ่งล้าน", 1);
  }

  if (baht_str.slice(0, 2) == "11") {
    _bahttext = _bahttext.replace("สิบหนึ่งล้าน", "สิบเอ็ดล้าน", 1);
  }

  if (_bahttext.length > 0) {
    _bahttext += "บาท";
  }

  if (satang_str == "00") {
    _bahttext += "ถ้วน";
  } else {
    if (satang_str.slice(0, 1) != "0") {
      _bahttext += _digit10[parseInt(satang_str.slice(0, 1))];
    }
    if (satang_str.slice(-1) != "0") {
      _bahttext += _number[parseInt(satang_str.slice(-1))];
    }
    _bahttext += "สตางค์";
    if (satang_str.slice(0, 1) != "0") {
      _bahttext = _bahttext.replace("หนึ่งสตางค์", "เอ็ดสตางค์", 1);
    }
  }
  return _bahttext;
};

export default bahttext;