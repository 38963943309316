export const DBConfig = {
  name: "freetools",
  version: 1,
  objectStoresMeta: [
    {
      store: "saveSetting",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "name", keypath: "name", options: { unique: false } },
        {
          name: "path",
          keypath: "path",
          options: { unique: false },
        },
        {
          name: "tool",
          keypath: "tool",
          options: { unique: false },
        },
        {
          name: "params",
          keypath: "params",
          options: { unique: false },
        },
        {
          name: "last_opened",
          keypath: "last_opened",
          options: { unique: false },
        },
      ],
    },
  ],
};
