import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import StandardLayout from "layouts/standard-layout";

import { Container } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import { useIndexedDB } from "react-indexed-db";

import HomeTable from "pages/home/Table";

const Home = () => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);

  const { getAll } = useIndexedDB("saveSetting");

  const updateRow = () => {
    try {
      getAll().then((result) => {
        setRows(result.reverse());
      });
    } catch {}
  };

  useEffect(updateRow, []);

  return (
    <StandardLayout>
      <SuiBox pt="100px" />
      <Container>
        <CdxCard p={3}>
          <SuiTypography variant="h6" component="h2">
            {t("Save setting")}
          </SuiTypography>

          {rows.length > 0 ? (
            <SuiBox mt={3}>
              <HomeTable rows={rows} updateRow={updateRow} />
            </SuiBox>
          ) : (
            <SuiBox mt={2}>
              <SuiTypography variant="body2">{t("No save")}</SuiTypography>
            </SuiBox>
          )}
        </CdxCard>
      </Container>
    </StandardLayout>
  );
};

export default Home;
