import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import saveCsvFromArr from "app/freetools/saveCsvFromArr";
import csvToArr from "app/freetools/csvToArr";

import gtag from "app/ga";

const Split = ({ config }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [rows, setRows] = useState([]);

  const summariseFiles = async (files) => {
    const newRows = [];
    let i = rows.length;
    files.forEach(async (e) => {
      newRows.push({
        idx: i,
        file: e,
        filename: e.name,
        filename_only: e.name.split(".").slice(0, -1).join("."),
        extension: e.name.split(".").at(-1),
        isProcessing: true,
      });
      i += 1;
    });
    setRows([...rows, ...newRows]);
    setFiles([]);
    gtag("event", "freetools_result");
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const setNewRows = (currentRows, newRow, idx) => {
    let newRows = [...currentRows];
    newRows[idx] = newRow;
    newRows = newRows.sort((a, b) => a.idx - b.idx);

    let i = -1;
    newRows = newRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    setRows(newRows);
  };

  const splitCsv = (result, newRow) => {
    const delimiter = config.delimiter || ",";

    let data = csvToArr(result.join("\n"), delimiter).slice(0, result.length);

    const groupData = {};

    const columns = config.columns ? config.columns.split(",") : [];
    const first_row = config.first_row || 2;

    data.slice(first_row - 1).forEach((e) => {
      const key = columns
        .map((k) => {
          const v = e.at(parseInt(k) - 1);
          if (v === "" || typeof v === "undefined") {
            return "$blank";
          } else {
            return v;
          }
        })
        .join(";");

      if (groupData[key]) {
        groupData[key] = [...groupData[key], e];
      } else {
        groupData[key] = [e];
      }
    });

    const header = [...data.slice(0, first_row - 1)];
    saveCsv(groupData, header, delimiter, newRow.filename_only);
  };

  const saveCsv = async (groupData, header, delimiter, filename) => {
    groupData = Object.entries(groupData);
    for (let i = 0; i < groupData.length; i++) {
      const [k, v] = groupData[i];

      let f = [...header, ...v];
      f = f.map((e) => {
        return e.map((p) => {
          if (p.includes(delimiter)) {
            return `"${p}"`;
          } else {
            return p;
          }
        });
      });
      const value_data = f.map((e) => {
        return e.join(delimiter);
      });

      const save_filename = config.incl_original ? `${filename}(${k})` : k;
      saveCsvFromArr(value_data, save_filename);
      await new Promise((r) => setTimeout(r, 500));
    }
  };

  const processCsv = async (rows, idx) => {
    let newRow = { ...rows[idx] };
    if (newRow.extension === "csv") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target.result.split("\n");
        if (result.at(-1) === "") {
          result.pop();
        }
        splitCsv(result, newRow);
        newRow = {
          ...rows[idx],
          isProcessing: false,
        };
        setNewRows(rows, newRow, idx);
      };
      reader.readAsText(newRow.file);
    } else {
      newRow = {
        ...rows[idx],
        isProcessing: false,
      };
      setNewRows(rows, newRow, idx);
    }
  };

  useEffect(() => {
    if (rows.filter((e) => e.isProcessing).length > 0) {
      if (!isProcessing) {
        setIsProcessing(true);
      }
      const idx = rows.findIndex((e) => e.isProcessing);
      processCsv(rows, idx);
    } else {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  }, [rows]);

  return (
    <Fragment>
      {!isProcessing ? (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      ) : (
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SuiBox pt={3}>
            <CircularProgress color="info" />
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption">
              {t("Processing...")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
    </Fragment>
  );
};

export default Split;
