import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

import SuiBox from "components/sui/SuiBox";
import SuiInput from "components/sui/SuiInput";
import SuiTypography from "components/sui/SuiTypography";

const CdxSwitch = ({
  label,
  mb,
  error,
  success,
  message,
  value,
  onChange,
  disabled,
  onBlur = () => {},
  spaceLabelAtTop = true,
  ...inputProps
}) => {
  const [finalLabel, setFinalLabel] = useState(label);
  const color = () => {
    if (error) {
      return "error";
    } else if (success) {
      return "success";
    } else {
      return "inherit";
    }
  };

  useEffect(() => {
    if (Array.isArray(label)) {
      if (value) {
        setFinalLabel(label.at(0));
      } else {
        setFinalLabel(label.at(1));
      }
    } else {
      return label;
    }
  }, [label, value]);

  return (
    <>
      {spaceLabelAtTop && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            {""}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox mb={mb ? mb : 0}>
        <SuiBox sx={{ display: "flex", alignItems: "center" }} py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            {disabled && <Switch checked={value} onChange={(e) => {}} />}
            {!disabled && (
              <Switch
                checked={value}
                onChange={(e) => {
                  onChange(!value);
                  onBlur(e);
                }}
              />
            )}
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {finalLabel}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {message && (
          <SuiBox px={1.5} sx={{ lineHeight: 1 }}>
            <SuiTypography color={color()} variant="caption">
              {message}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
};

export default CdxSwitch;
