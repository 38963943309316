import { useTranslation } from "react-i18next";

import CdxDataTable from "components/cdx/CdxDataTable";

import { useIndexedDB } from "react-indexed-db";
import SuiButton from "components/sui/SuiButton";
import { Grid, Icon } from "@mui/material";

import { useNavigate } from "react-router-dom";

import gtag from "app/ga";

const HomeTable = ({ rows, updateRow }) => {
  const { t } = useTranslation();

  const { update, deleteRecord } = useIndexedDB("saveSetting");

  const navigate = useNavigate();
  const renderAction = (r) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <SuiButton
            circular
            iconOnly
            size="small"
            color="info"
            variant="gradient"
            onClick={() => {
              update({ ...r, last_opened: Date.now() });
              gtag("event", "freetools_opensave", { tool: r.path });
              navigate(`${r.path}?${r.params}`);
            }}
          >
            <Icon>file_open</Icon>
          </SuiButton>
        </Grid>
        <Grid item>
          <SuiButton
            circular
            iconOnly
            size="small"
            color="error"
            variant="gradient"
            onClick={() => {
              deleteRecord(r.id).then(() => {
                updateRow();
              });
              gtag("event", "freetools_deletesave", { tool: r.path });
            }}
          >
            <Icon>delete</Icon>
          </SuiButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <CdxDataTable
      entriesPerPage={{
        defaultValue: 10,
        entries: [
          { value: 5, label: 5 },
          { value: 10, label: 10 },
          { value: 15, label: 15 },
          { value: 20, label: 20 },
          { value: 25, label: 25 },
          { value: -1, label: t("Show all") },
        ],
      }}
      canSearch
      showSelectEntriesPerPage={true}
      table={{
        columns: [
          {
            Header: t("Action"),
            accessor: "any",
            Cell: ({ cell }) => {
              return renderAction(cell.row.original);
            },
          },
          {
            Header: t("Name"),
            accessor: "name",
          },
          {
            Header: t("Tool"),
            accessor: "tool",
            Cell: ({ value }) => {
              return t(value);
            },
          },
          {
            Header: t("Last opened"),
            accessor: "last_opened",
            Cell: ({ value }) => {
              const d = new Date(value);
              return d.toLocaleString();
            },
          },
        ],
        rows: rows,
      }}
    />
  );
};

export default HomeTable;
