import { useEffect } from "react";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";

import getRoutes from "app/utils/getRoutes";

import { navbarRoutes } from "routes";
import { normalRoutes } from "routes";

import NotFound404 from "pages/statics/NotFound404";

import { GA_TRACKING_ID } from "settings/ga";
import gtag from "app/ga";

import { useTranslation } from "react-i18next";

import useConsent from "app/utils/hooks/useConsent";
import setConsent from "app/utils/consent/setConsent";

const AppRoute = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [consent] = useConsent();

  const currentLanguage = useTranslation().i18n.language;

  const updateGtagConfig = () => {
    const queryStr = searchParams.toString();
    if (queryStr) {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
        queries: queryStr,
        language_code: currentLanguage,
      });
    } else {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
        language_code: currentLanguage,
      });
    }
  };

  useEffect(() => {
    if (consent.necessary) {
      setConsent(consent.performance);
      updateGtagConfig();
    }
  }, [consent]);

  useEffect(updateGtagConfig, [pathname, searchParams, currentLanguage]);

  return (
    <Routes>
      {getRoutes(navbarRoutes)}
      {getRoutes(normalRoutes)}
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default AppRoute;
