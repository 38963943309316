import { Fragment, useState, useEffect } from "react";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";

import KeywordsTable from "./table";

import { useTranslation } from "react-i18next";
import { Grid, Icon } from "@mui/material";
import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";

import { rootElement } from "settings";

const Keywords = ({ keywords, setKeywords }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState({
    column: null,
    criteria: null,
    keyword: null,
  });

  const [disabled, setDisabled] = useState(true);

  const options = [
    { value: "equals", label: t("Equals") },
    { value: "startsWith", label: t("Starts with") },
    { value: "contains", label: t("Contains") },
    { value: "donotcontains", label: t("Do not contains") },
    { value: "isblank", label: t("Is blank") },
    { value: "isnotblank", label: t("Is not blank") },
  ];

  const setValueKey = (val, key) => {
    if (key === "criteria" && ["isblank", "isnotblank"].includes(val)) {
      setValue({ ...value, [key]: val, keyword: null });
    } else {
      setValue({ ...value, [key]: val });
    }
  };

  useEffect(() => {
    if (!value.column) {
      setDisabled(true);
    } else if (!value.criteria) {
      setDisabled(true);
    } else if (
      !value.keyword &&
      !["isblank", "isnotblank"].includes(value.criteria)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [value]);

  const addKeywords = () => {
    setKeywords([...keywords, { ...value, idx: keywords.length }]);
  };

  return (
    <Fragment>
      <SuiTypography variant="h6" component="h2">
        {t("Keywords")}
      </SuiTypography>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <CdxInput
            label={t("Column")}
            value={value.column || ""}
            onChange={(e) => {
              setValueKey(e.target.value, "column");
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <CdxSelect
            label={t("Criteria")}
            value={value.criteria}
            onChange={(e) => {
              setValueKey(e, "criteria");
            }}
            options={options}
            menuPortalTarget={rootElement}
          />
        </Grid>
        <Grid item xs>
          <CdxInput
            label={t("Keyword")}
            value={value.keyword || ""}
            onChange={(e) => {
              setValueKey(e.target.value, "keyword");
            }}
            disabled={["isblank", "isnotblank"].includes(value.criteria)}
          />
        </Grid>
        <Grid item xs="auto">
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {""}
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <SuiButton
              iconOnly
              variant="gradient"
              color="success"
              onClick={addKeywords}
              disabled={disabled}
            >
              <Icon>add</Icon>
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      {keywords.length > 0 && (
        <SuiBox mt={2}>
          <KeywordsTable
            rows={keywords}
            setRows={setKeywords}
            options={options}
          />
        </SuiBox>
      )}
    </Fragment>
  );
};

export default Keywords;
