import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { useTranslation } from "react-i18next";

const AllowPerformanceCookie = () => {
  const { t } = useTranslation();
  return (
    <SuiBox
      minHeight={"100vh"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <SuiTypography variant="h3" color="warning" textGradient>
        {t("Please accept performance cookies to use Codex Freetools")}
      </SuiTypography>
    </SuiBox>
  );
};

export default AllowPerformanceCookie;
