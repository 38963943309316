import { BASE_URL, URL_FREETOOLS, URL_CODEXSHEET } from "settings";

export default {
  company: {
    href: BASE_URL,
    name: "Codex Lab Co., Ltd.",
    wordafter: ", a Thai limited liability company. All rights reserved.",
  },
  links: [
    { href: `${BASE_URL}`, name: "Our website" },
    { href: "mailto:info@codexlab.co", name: "Email us" },
    { href: `${BASE_URL}/privacy`, name: "Privacy policy" },
  ],
  light: false,
};
