import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import CdxInput from "components/cdx/CdxInput";
import { useTranslation } from "react-i18next";
import CdxTagInput from "components/cdx/CdxTagInput";

const Setting = ({ config, setConfig }) => {
  const { t } = useTranslation();

  const setConfigKey = (value, key) => {
    setConfig({ ...config, [key]: value });
  };

  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} xl={2}>
          <CdxInput
            label={t("Header rows")}
            type="number"
            value={config.header_row}
            onChange={(e) => {
              setConfigKey(e.target.value, "header_row");
            }}
            message={t("If there is no header, please type -1.")}
          />
        </Grid>
        <Grid item xs={12} md={9} xl={10}>
          <CdxTagInput
            label={t("Exclude sheets")}
            tags={config.exclude_sheets || []}
            placeholder={t("Add more sheet name")}
            onChange={(e) => {
              setConfigKey(e, "exclude_sheets");
            }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default Setting;
