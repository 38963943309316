const saveCsvFromArr = (arr, filename) => {
  const downloadBlob = new Blob([arr.join("\n")]);
  const downloadUrl = window.URL.createObjectURL(downloadBlob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("id", `saveCsvFromArr_${filename}.csv`);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default saveCsvFromArr;
