import { useTranslation } from "react-i18next";

import { Icon } from "@mui/material";
import { Grid } from "@mui/material";

import CdxDataTable from "components/cdx/CdxDataTable";

import excelDateToISO from "app/utils/excelDateToISO";

const Tawi50Table = ({ rows, setRows }) => {
  const { t } = useTranslation();

  const deleteRow = (idx) => {
    let newRows = rows.filter((e) => e.idx !== idx);
    setRows(newRows);
  };

  return (
    <CdxDataTable
      entriesPerPage={{
        defaultValue: -1,
        entries: [{ value: -1, label: t("Show all") }],
      }}
      showSelectEntriesPerPage={false}
      showTotalEntries={false}
      table={{
        columns: [
          {
            Header: t("Name"),
            accessor: "name",
          },
          {
            Header: t("ID"),
            accessor: "id",
          },
          {
            Header: t("Document Date"),
            accessor: "doc_date",
            Cell: ({ value }) => {
              try {
                return excelDateToISO(value);
              } catch {
                return t("Invalid date")
              }
              
            },
          },
          {
            Header: t("Amount"),
            accessor: "amount",
            Cell: ({ value }) => {
              return value.toLocaleString();
            },
          },
          {
            Header: t("WHT"),
            accessor: "wht",
            Cell: ({ value }) => {
              return value.toLocaleString();
            },
          },
          {
            Header: t("Action"),
            accessor: "idx",
            Cell: ({ value }) => {
              return (
                <Grid container height="100%" spacing={2}>
                  <Grid
                    item
                    onClick={() => {
                      deleteRow(value);
                    }}
                  >
                    <Icon color="error" sx={{ cursor: "pointer" }}>
                      delete
                    </Icon>
                  </Grid>
                </Grid>
              );
            },
          },
        ],
        rows: rows,
      }}
    />
  );
};

export default Tawi50Table;
