import { BrowserRouter } from "react-router-dom";

import { SoftUIControllerProvider } from "context";

import AppRoute from "AppRoute";

import PrivacyConsent from "layouts/privacy-consent";

import { initDB } from "react-indexed-db";
import { DBConfig } from "settings/dbConfig";

initDB(DBConfig);

const App = () => {
 
  return (
    <SoftUIControllerProvider>
      <BrowserRouter>
        <AppRoute />
        <PrivacyConsent />
      </BrowserRouter>
    </SoftUIControllerProvider>
  );
};

export default App;
