import Icon from "@mui/material/Icon";
import { BASE_PATH } from "settings";

import Home from "pages/home";

import MergeCsv from "pages/csv/merge-csv";
import RemoveRowsCsv from "pages/csv/remove-rows";
import SplitDataCsv from "pages/csv/split-data";

import MergeXlsx from "pages/excel/merge-xlsx";
import MergeSheets from "pages/excel/merge-sheets";
import SplitSheets from "pages/excel/split-sheets";
import RemoveRowsExcel from "pages/excel/remove-rows";
import SplitDataExcel from "pages/excel/split-data";

import MergePdf from "pages/pdf/merge-pdf";

import Tawi50 from "pages/tax/tawi50";

import NotFound404 from "pages/statics/NotFound404";

export const navbarRoutes = [
  {
    name: "CSV",
    key: "/csv",
    icon: <Icon>description</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "file management",
        collapse: [
          {
            name: "Merge CSV",
            path: `${BASE_PATH}/csv/merge-csv`,
            element: <MergeCsv />,
          },
        ],
      },
      {
        name: "preparing data",
        collapse: [
          {
            name: "Remove Rows",
            path: `${BASE_PATH}/csv/remove-rows`,
            element: <RemoveRowsCsv />,
          },
          {
            name: "Split Data",
            path: `${BASE_PATH}/csv/split`,
            element: <SplitDataCsv />,
          },
        ],
      },
    ],
  },
  {
    name: "Excel",
    key: "/excel",
    icon: <Icon>border_all</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "File Management",
        collapse: [
          {
            name: "Merge XLSX",
            path: `${BASE_PATH}/excel/merge-xlsx`,
            element: <MergeXlsx />,
          },
          {
            name: "Merge Sheets",
            path: `${BASE_PATH}/excel/merge-sheets`,
            element: <MergeSheets />,
          },
          {
            name: "Split Sheets",
            path: `${BASE_PATH}/excel/split-sheets`,
            element: <SplitSheets />,
          },
        ],
      },
      {
        name: "Preparing data",
        collapse: [
          {
            name: "Remove Rows",
            path: `${BASE_PATH}/excel/remove-rows`,
            element: <RemoveRowsExcel />,
          },
          {
            name: "Split Data",
            path: `${BASE_PATH}/excel/split-data`,
            element: <SplitDataExcel />,
          },
          // {
          //   name: "Thai Date Correction",
          //   path: `${BASE_PATH}/excel/thai-date-correction`,
          //   element: <NotFound404 />,
          // },
        ],
      },
    ],
  },
  {
    name: "PDF",
    key: "/pdf",
    icon: <Icon>picture_as_pdf</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "file management",
        collapse: [
          {
            name: "Merge PDF",
            path: `${BASE_PATH}/pdf/merge-pdf`,
            element: <MergePdf />,
          },
        ],
      },
    ],
  },
  {
    name: "Tax",
    key: "/tax",
    icon: <Icon>money_off</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Thai Tax",
        collapse: [
          {
            name: "50 Tawi",
            path: `${BASE_PATH}/tax/50-tawi`,
            element: <Tawi50 />,
          },
        ],
      },
    ],
  },
  // {
  //   name: "Accounting",
  //   key: "/accounting",
  //   icon: <Icon>payments</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 1,
  //   collapse: [
  //     {
  //       name: "Calculation",
  //       collapse: [
  //         {
  //           name: "Find combination",
  //           path: `${BASE_PATH}/accounting/find-combination`,
  //           element: <FindCombination />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const normalRoutes = [
  {
    key: "home",
    path: "/*",
    element: <Home />,
  },
];
