import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";

import { BASE_APP_URL } from "settings";

import queryStringSerializer from "app/utils/queryStringSerializer";

import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Grid, Icon } from "@mui/material";
import copyToClipboard from "app/utils/copyToClipboard";

import { useIndexedDB } from "react-indexed-db";
import { useLocation, useNavigate } from "react-router-dom";

import gtag from "app/ga";

import { encodeB64 } from "app/utils/base64";

const Save = ({ config, keywords, submitting }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [saveName, setSaveName] = useState("");
  const [isCopy, setIsCopy] = useState(false);

  const [link, setLink] = useState("");

  const { add, getAll } = useIndexedDB("saveSetting");

  const saveToDb = async () => {
    if (submitting) {
      const j = encodeB64(JSON.stringify(config));
      const k = encodeB64(JSON.stringify(keywords));
      let cleanSaveName = saveName || "New save";
      let duplicateName = true;

      try {
        await getAll().then((result) => {
          if (
            result.filter((e) => {
              return e.name === cleanSaveName;
            }).length === 0
          ) {
            duplicateName = false;
          }

          let i = 0;
          while (duplicateName) {
            i += 1;
            const tempName = `${cleanSaveName} (${i})`;
            if (
              result.filter((e) => {
                return e.name === tempName;
              }).length === 0
            ) {
              cleanSaveName = tempName;
              duplicateName = false;
            }
          }
        });
      } catch {}

      gtag("event", "freetools_savetool");

      add({
        name: cleanSaveName,
        path: pathname,
        tool: "Split data - Excel",
        last_opened: Date.now(),
        params: queryStringSerializer({ config: j, keywords: k }),
      });

      navigate("/");
    }
  };

  useEffect(() => {
    saveToDb();
  }, [submitting]);

  useEffect(() => {
    const j = encodeB64(JSON.stringify(config));
    const k = encodeB64(JSON.stringify(keywords));
    setLink(
      `${BASE_APP_URL}${pathname}?${queryStringSerializer({
        config: j,
        keywords: k,
      })}`
    );
  }, [config]);

  const copy = () => {
    setIsCopy(true);
    copyToClipboard(link);
    gtag("event", "freetools_copylink");
    setTimeout(() => {
      setIsCopy(false);
    }, 5000);
  };

  return (
    <SuiBox display="flex" flexDirection="column">
      <SuiBox mb={2}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs>
            <CdxInput
              label={t("Share link")}
              value={link}
              icon={{
                component: "share",
                direction: "left",
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <SuiButton
              variant="gradient"
              color={isCopy ? "success" : "warning"}
              onClick={copy}
            >
              {isCopy && (
                <Fragment>
                  <Icon>done</Icon>&nbsp;&nbsp;
                </Fragment>
              )}
              {t("copy to clipboard")}
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={2}>
        <CdxInput
          label={t("Save name")}
          value={saveName}
          placeholder={t("New save (for blank)")}
          onChange={(e) => {
            setSaveName(e.target.value);
          }}
          icon={{
            component: "save",
            direction: "left",
          }}
          message={t(
            "This would save your setting in your devices. We could not access to your save."
          )}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default Save;
