import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import CdxInput from "components/cdx/CdxInput";
import { useTranslation } from "react-i18next";

const Setting = ({ config, setConfig }) => {
  const { t } = useTranslation();

  const setConfigKey = (value, key) => {
    setConfig({ ...config, [key]: value });
  };

  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item xs>
          <CdxInput
            label={t("Delimiter")}
            value={config.delimiter || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "delimiter");
            }}
            message={t("Leave blank for comma delimiter “,”")}
          />
        </Grid>
        <Grid item xs>
          <CdxInput
            label={t("File name subfix")}
            value={config.filename_subfix || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "filename_subfix");
            }}
            message={t("Leave blank for no subfix")}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default Setting;
