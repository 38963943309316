const savePdfFromByteArr = (pdfBytes, filename) => {
  const downloadBlob = new Blob([pdfBytes]);
  const downloadUrl = window.URL.createObjectURL(downloadBlob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("id", `savePdfFromByteArr${filename}.pdf`);
  link.setAttribute("download", `${filename}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default savePdfFromByteArr;
