import { useTranslation } from "react-i18next";

import { Icon } from "@mui/material";
import { Grid } from "@mui/material";

import CdxDataTable from "components/cdx/CdxDataTable";

const KeywordsTable = ({ rows, setRows, options }) => {
  const { t } = useTranslation();

  const sortRowByIdx = (r) => {
    let sortedRows = r.sort((a, b) => a.idx - b.idx);
    let i = -1;

    sortedRows = sortedRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    return sortedRows;
  };

  const deleteRow = (idx) => {
    let newRows = rows.filter((e, id) => id !== idx);
    newRows = sortRowByIdx(newRows);
    setRows(newRows);
  };

  return (
    <CdxDataTable
      entriesPerPage={{
        defaultValue: -1,
        entries: [{ value: -1, label: t("Show all") }],
      }}
      showSelectEntriesPerPage={false}
      showTotalEntries={false}
      table={{
        columns: [
          {
            Header: t("Column Index"),
            accessor: "column",
          },
          {
            Header: t("Criteria"),
            accessor: "criteria",
            Cell: ({ value }) => {
              return options.find((e) => e.value === value).label;
            },
          },
          {
            Header: t("Keyword"),
            accessor: "keyword",
          },
          {
            Header: t("Action"),
            accessor: "idx",
            Cell: ({ value }) => {
              return (
                <Grid container height="100%" spacing={2}>
                  <Grid
                    item
                    onClick={() => {
                      deleteRow(value);
                    }}
                  >
                    <Icon color="error" sx={{ cursor: "pointer" }}>
                      delete
                    </Icon>
                  </Grid>
                </Grid>
              );
            },
          },
        ],
        rows: rows,
      }}
    />
  );
};

export default KeywordsTable;
