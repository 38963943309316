import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import saveCsvFromArr from "app/freetools/saveCsvFromArr";
import csvToArr from "app/freetools/csvToArr";

import gtag from "app/ga";

const Remove = ({ keywords, config }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [rows, setRows] = useState([]);

  const summariseFiles = async (files) => {
    const newRows = [];
    let i = rows.length;
    files.forEach(async (e) => {
      newRows.push({
        idx: i,
        file: e,
        filename: e.name,
        filename_only: e.name.split(".").slice(0, -1).join("."),
        extension: e.name.split(".").at(-1),
        isProcessing: true,
      });
      i += 1;
    });
    setRows([...rows, ...newRows]);
    setFiles([]);
    gtag("event", "freetools_result");
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const setNewRows = (currentRows, newRow, idx) => {
    let newRows = [...currentRows];
    newRows[idx] = newRow;
    newRows = newRows.sort((a, b) => a.idx - b.idx);

    let i = -1;
    newRows = newRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    setRows(newRows);
  };

  const removeRowsCsv = (result, newRow) => {
    const delimiter = config.delimiter || ",";

    let data = csvToArr(result.join("\n"), delimiter).slice(0, result.length);

    keywords.forEach((key) => {
      const column = key.column - 1;
      const criteria = key.criteria;
      const keyword = key.keyword;

      if (column >= 0) {
        if (criteria === "equals") {
          data = data.filter((e) => !((e.at(column) || "") === keyword));
        } else if (criteria === "startsWith") {
          data = data.filter((e) => !(e.at(column) || "").startsWith(keyword));
        } else if (criteria === "contains") {
          data = data.filter((e) => !(e.at(column) || "").includes(keyword));
        } else if (criteria === "donotcontains") {
          data = data.filter((e) => (e.at(column) || "").includes(keyword));
        } else if (criteria === "isblank") {
          data = data.filter((e) => {
            if (e.at(column) === "") {
              return false;
            }
            if (typeof e.at(column) === "undefined") {
              return false;
            }
            return true;
          });
        } else if (criteria === "isnotblank") {
          data = data.filter((e) => {
            if (e.at(column) === "") {
              return true;
            }
            if (typeof e.at(column) === "undefined") {
              return true;
            }
            return false;
          });
        }
      }
    });

    data = data.map((e) => {
      return e.map((p) => {
        if (p.includes(delimiter)) {
          return `"${p}"`;
        } else {
          return p;
        }
      });
    });

    data = data.map((e) => {
      return e.join(delimiter);
    });

    saveCsvFromArr(
      data,
      `${newRow.filename_only}${config.filename_subfix || ""}`
    );
  };

  const processCsv = async (rows, idx) => {
    let newRow = { ...rows[idx] };
    if (newRow.extension === "csv") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target.result.split("\n");
        if (result.at(-1) === "") {
          result.pop();
        }
        removeRowsCsv(result, newRow);
        newRow = {
          ...rows[idx],
          isProcessing: false,
        };
        setNewRows(rows, newRow, idx);
      };
      reader.readAsText(newRow.file);
    } else {
      newRow = {
        ...rows[idx],
        isProcessing: false,
      };
      setNewRows(rows, newRow, idx);
    }
  };

  useEffect(() => {
    if (rows.filter((e) => e.isProcessing).length > 0) {
      if (!isProcessing) {
        setIsProcessing(true);
      }
      const idx = rows.findIndex((e) => e.isProcessing);
      processCsv(rows, idx);
    } else {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  }, [rows]);

  return (
    <Fragment>
      {!isProcessing ? (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      ) : (
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SuiBox pt={3}>
            <CircularProgress color="info" />
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption">
              {t("Processing...")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
    </Fragment>
  );
};

export default Remove;
