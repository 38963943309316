/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Material Kit 2 PRO React base styles
import typography from "assets/theme/base/typography";

import { useState, useEffect, useRef, Fragment } from "react";

import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

function SimpleFooter({ company, links, light }) {
  const { t } = useTranslation();

  const { href, name, wordafter } = company;
  const { size } = typography;

  const footerRef = useRef();
  const autoSpaceRef = useRef();
  const { pathname } = useLocation();

  const [autoSpace, setAutoSpace] = useState(0);

  const autoCdxFooter = () => {
    if (!footerRef.current) {
      return;
    }

    const body = document.body;
    const html = document.documentElement;

    const footerPos = footerRef.current.getBoundingClientRect();
    const autoSpaceHeight = autoSpaceRef.current.getBoundingClientRect().height;

    const bodyHeight = Math.max(body.scrollHeight, body.offsetHeight);

    const rawBodyHeight = bodyHeight - autoSpaceHeight;

    const windowHeight = html.clientHeight;

    if (rawBodyHeight > windowHeight - 24) {
      if (autoSpaceHeight === 0) {
        return;
      } else {
        setAutoSpace(0);
      }
      return;
    }

    if (footerPos.bottom === windowHeight - 24) {
    } else if (footerPos.bottom < windowHeight - 24) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 24 - footerPos.bottom));
    } else if (footerPos.bottom - autoSpaceHeight < windowHeight - 24) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 24 - footerPos.bottom));
    }
  };

  const footerTimeout = () => {
    setTimeout(autoCdxFooter, 200);
  };

  useEffect(() => {
    window.addEventListener("resize", footerTimeout);
    document.body.addEventListener("click", footerTimeout);
    document.body.addEventListener("keypress", footerTimeout);
    footerTimeout();

    return () => {
      window.removeEventListener("resize", footerTimeout);
      document.body.removeEventListener("click", footerTimeout);
      document.body.removeEventListener("keypress", footerTimeout);
    };
  }, []);

  useEffect(() => {
    footerTimeout();
  }, [pathname]);

  const renderLinks = () =>
    links.map((link, key) => (
      <SuiBox
        key={link.name}
        component="li"
        pl={key === 0 ? 0 : 2}
        pr={key === links.length - 1 ? 0 : 2}
        lineHeight={1}
      >
        <Link href={link.href} target="_blank">
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={light ? "white" : "text"}
          >
            {t(link.name)}
          </SuiTypography>
        </Link>
      </SuiBox>
    ));

  return (
    <Fragment>
      <SuiBox ref={autoSpaceRef} sx={{ height: autoSpace }} />
      <SuiBox ref={footerRef}>
        <Container>
          <SuiBox
            width="100%"
            display="flex"
            pb={autoSpace == 0 ? 2 : 0}
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              color={light ? "white" : "text"}
              fontSize={size.sm}
            >
              &copy; {new Date().getFullYear()}
              <Link href={href} target="_blank">
                <SuiTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  &nbsp;{name}
                </SuiTypography>
              </Link>
              {wordafter}
            </SuiBox>
            <SuiBox
              component="ul"
              sx={({ breakpoints }) => ({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                listStyle: "none",
                mt: 3,
                mb: 0,
                p: 0,

                [breakpoints.up("lg")]: {
                  mt: 0,
                },
              })}
            >
              {renderLinks()}
            </SuiBox>
          </SuiBox>
        </Container>
      </SuiBox>
    </Fragment>
  );
}

// Setting default values for the props of SimpleFooter
SimpleFooter.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  links: [
    { href: "https://www.creative-tim.com/", name: "Creative Tim" },
    { href: "https://www.creative-tim.com/presentation", name: "About Us" },
    { href: "https://www.creative-tim.com/blog", name: "Blog" },
    { href: "https://www.creative-tim.com/license", name: "License" },
  ],
  light: false,
};

// Typechecking props for the SimpleFooter
SimpleFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
  light: PropTypes.bool,
};

export default SimpleFooter;
