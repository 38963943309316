import { Fragment, useEffect, useState } from "react";

import SuiBox from "components/sui/SuiBox";

import DefaultNavbar from "components/cdx/CdxNavbars/DefaultNavbar";
import SimpleFooter from "components/cdx/CdxFooters/SimpleFooter";

import { navbarRoutes } from "routes";
import footerRoutes from "footer.routes";

import AllowPerformanceCookie from "pages/statics/AllowPerformanceCookie";
import useConsent from "app/utils/hooks/useConsent";

const StandardLayout = ({ children }) => {
  const [consent] = useConsent();

  return (
    <Fragment>
      <DefaultNavbar routes={navbarRoutes} sticky />
      {consent.performance ? children : <AllowPerformanceCookie />}
      <SuiBox>
        <SimpleFooter {...footerRoutes} />
      </SuiBox>
    </Fragment>
  );
};

export default StandardLayout;
