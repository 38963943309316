import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// MergeCsv page components
import Keywords from "./Keywords";
import Setting from "./Setting";
import Remove from "./Remove";
import Save from "./Save";

import StandardLayout from "layouts/standard-layout";

import gtag from "app/ga";

import { decodeB64 } from "app/utils/base64";

const getSteps = () => {
  return ["Keywords", "Setting", "Remove rows", "Save & Share"];
};

const RemoveRowsCsv = ({}) => {
  const { t } = useTranslation();

  const [keywords, setKeywords] = useState([]);

  const [disabledNext, setDisabledNext] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [config, setConfig] = useState({
    filename_subfix: null,
    delimiter: null,
  });

  useEffect(() => {
    let configQuery = searchParams.get("config");
    if (configQuery) {
      try {
        configQuery = decodeB64(configQuery);
        configQuery = JSON.parse(configQuery);
        setConfig({ ...configQuery });
      } catch {}
    }
    let keywordsQuery = searchParams.get("keywords");
    if (keywordsQuery) {
      try {
        keywordsQuery = decodeB64(keywordsQuery);
        keywordsQuery = JSON.parse(keywordsQuery);
        setKeywords([...keywordsQuery]);
      } catch {}
    }
  }, [searchParams]);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setDisabledNext(false);
    gtag("event", "freetools_next", {
      current_step: activeStep,
      next_step: activeStep + 1,
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    gtag("event", "freetools_back", {
      current_step: activeStep,
      back_step: activeStep - 1,
    });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Keywords keywords={keywords} setKeywords={setKeywords} />;
      case 1:
        return <Setting config={config} setConfig={setConfig} />;
      case 2:
        return <Remove keywords={keywords} config={config} />;
      case 3:
        return (
          <Save config={config} keywords={keywords} submitting={submitting} />
        );
      default:
        return null;
    }
  };

  const onSubmit = () => {
    setSubmitting(true);
  };

  return (
    <StandardLayout>
      <SuiBox pt="100px" pb={8}>
        <Grid container justifyContent="center" px={{ xs: 2, sm: 3, md: 5 }}>
          <Grid item xs={12} lg={10} xxl={9}>
            <SuiBox mt={0} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h4" component="h1" fontWeight="bold">
                  {t("Remove rows from CSV")}
                </SuiTypography>
              </SuiBox>
              <SuiTypography
                variant="h6"
                component="h2"
                fontWeight="regular"
                color="secondary"
              >
                {t("Remove rows based on keywords")}
              </SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <SuiBox p={3}>
                <SuiBox>
                  {getStepContent(activeStep)}
                  <SuiBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <SuiBox />
                    ) : (
                      <SuiButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        {t("back")}
                      </SuiButton>
                    )}
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : onSubmit}
                      disabled={disabledNext || submitting}
                    >
                      {isLastStep ? t("save") : t("next")}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </StandardLayout>
  );
};

export default RemoveRowsCsv;
