import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import * as XLSX from "xlsx/xlsx.mjs";

import gtag from "app/ga";

const Remove = ({ keywords, config }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [rows, setRows] = useState([]);

  const summariseFiles = async (files) => {
    const newRows = [];
    let i = rows.length;
    files.forEach(async (e) => {
      newRows.push({
        idx: i,
        file: e,
        filename: e.name,
        filename_only: e.name.split(".").slice(0, -1).join("."),
        extension: e.name.split(".").at(-1),
        isProcessing: true,
      });
      i += 1;
    });
    setRows([...rows, ...newRows]);
    setFiles([]);
    gtag("event", "freetools_result");
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const setNewRows = (currentRows, newRow, idx) => {
    let newRows = [...currentRows];
    newRows[idx] = newRow;
    newRows = newRows.sort((a, b) => a.idx - b.idx);

    let i = -1;
    newRows = newRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    setRows(newRows);
  };

  const removeRowsXlsx = (wb, newRow) => {
    const workbook = XLSX.utils.book_new();

    let worksheet = null;

    newRow.sheetNames.forEach((sn) => {
      if (!config.exclude_sheets.includes(sn)) {
        let data = XLSX.utils.sheet_to_json(wb.Sheets[sn], { header: 1 });
        keywords.forEach((key) => {
          let column = null;
          try {
            column = XLSX.utils.decode_col(key.column);
          } catch {
            return;
          }
          const criteria = key.criteria;
          const keyword = key.keyword;
          if (column >= 0) {
            if (criteria === "equals") {
              data = data.filter((e) => !((e.at(column) || "") === keyword));
              if (`${parseFloat(keyword)}` === keyword) {
                data = data.filter(
                  (e) => !(e.at(column) == parseFloat(keyword))
                );
              }
            } else if (criteria === "startsWith") {
              data = data.filter((e) => {
                if (typeof e.at(column) === "string") {
                  return !(e.at(column) || "").startsWith(keyword);
                }
                return true;
              });
            } else if (criteria === "contains") {
              data = data.filter((e) => {
                if (typeof e.at(column) === "string") {
                  return !(e.at(column) || "").includes(keyword);
                }
                return true;
              });
            } else if (criteria === "donotcontains") {
              data = data.filter((e) => {
                if (typeof e.at(column) === "string") {
                  return (e.at(column) || "").includes(keyword);
                }
                return true;
              });
            } else if (criteria === "isblank") {
              data = data.filter((e) => {
                return typeof e.at(column) !== "undefined";
              });
            } else if (criteria === "isnotblank") {
              data = data.filter((e) => {
                return typeof e.at(column) === "undefined";
              });
            }
          }
        });
        worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, sn);
      } else {
        XLSX.utils.book_append_sheet(workbook, wb.Sheets[sn], sn);
      }
    });

    XLSX.writeFile(
      workbook,
      `${newRow.filename_only}${config.filename_subfix || ""}.xlsx`
    );
  };

  const processXlsx = async (rows, idx) => {
    let newRow = { ...rows[idx] };
    if (newRow.extension === "xlsx") {
      const data = await newRow.file.arrayBuffer();
      const wb = XLSX.read(data, {
        cellNF: true,
        cellStyles: true,
        cellDates: true,
      });
      newRow = {
        ...rows[idx],
        sheetNames: wb.SheetNames,
        noOfSheet: wb.SheetNames.length,
        workbook: wb,
        isProcessing: false,
      };
      removeRowsXlsx(wb, newRow);
      setNewRows(rows, newRow, idx);
    } else {
      newRow = {
        ...rows[idx],
        noOfSheet: t("Invalid file type"),
        isProcessing: false,
      };
      setNewRows(rows, newRow, idx);
    }
  };

  useEffect(() => {
    if (rows.filter((e) => e.isProcessing).length > 0) {
      if (!isProcessing) {
        setIsProcessing(true);
      }
      const idx = rows.findIndex((e) => e.isProcessing);
      processXlsx(rows, idx);
    } else {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  }, [rows]);

  return (
    <Fragment>
      {!isProcessing ? (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      ) : (
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SuiBox pt={3}>
            <CircularProgress color="info" />
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption">
              {t("Processing...")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
    </Fragment>
  );
};

export default Remove;
