import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import MergePdfTable from "./table";

import * as XLSX from "xlsx/xlsx.mjs";

import { useTranslation } from "react-i18next";
import SuiButton from "components/sui/SuiButton";

const File = ({ rows, setRows }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const summariseFiles = async (files) => {
    files.forEach(async (file) => {
      await processXlsx(file);
    });
    setFiles([]);
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const processXlsx = async (file) => {
    if (file.name.split(".").at(-1) === "xlsx") {
      const data = await file.arrayBuffer();
      const wb = XLSX.read(data, {
        cellNF: true,
      });
      if (wb.SheetNames.includes("50TawiList")) {
        const ws = wb.Sheets["50TawiList"];
        let newRows = [...rows, ...XLSX.utils.sheet_to_json(ws)];
        newRows = newRows.map((e, id) => {
          return { ...e, idx: id };
        });
        setRows(newRows);
      }
    }
  };

  const downloadTemplate = () => {
    let workbook = XLSX.utils.book_new();
    let worksheet = null;
    worksheet = XLSX.utils.json_to_sheet(
      [
        {
          book_no: null,
          doc_no: null,
          doc_date: null,
          name: null,
          id: null,
          address: null,
          seq_no: null,
          doc_type: null,
          pay_type: null,
          payment_date: null,
          amount: null,
          wht: null,
          remark: null,
          wht_type: null,
          gpf: null,
          social_security: null,
          pvf: null,
        },
      ],
      {
        headers: [
          "book_no",
          "doc_no",
          "doc_date",
          "name",
          "id",
          "address",
          "seq_no",
          "doc_type",
          "pay_type",
          "payment_date",
          "amount",
          "wht",
          "remark",
          "wht_type",
          "gpf",
          "social_security",
          "pvf",
        ],
      }
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, `50TawiList`);
    XLSX.writeFile(workbook, `50Tawi_upload_template.xlsx`);
  };

  return (
    <Fragment>
      {!isProcessing && (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      )}
      {rows.length > 0 ? (
        <SuiBox mt={2}>
          <MergePdfTable rows={rows} setRows={setRows} />
        </SuiBox>
      ) : (
        <SuiBox mt={2}>
          <SuiButton
            onClick={downloadTemplate}
            size="small"
            color="success"
            variant="gradient"
          >
            {t("Download upload template")}
          </SuiButton>
        </SuiBox>
      )}
    </Fragment>
  );
};

export default File;
