import { useState } from "react";

import SuiBox from "components/sui/SuiBox";

import CdxButton from "components/cdx/CdxButton";

import { useTranslation } from "react-i18next";

import saveCsvFromArr from "app/freetools/saveCsvFromArr";
import CdxInput from "components/cdx/CdxInput";

import gtag from "app/ga";

const Merge = ({ rows, config, saveOptions, setSaveOptions }) => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(false);

  const setSaveOptionsKey = (value, key) => {
    setSaveOptions({ ...saveOptions, [key]: value });
  };

  const downloadMergeFile = () => {
    setIsDownload(true);

    const combineData = [];
    let addHeader = false;

    let header_row = `${config.header_row}`;
    if (header_row !== "-1") {
      try {
        if (header_row.search("-") > 0) {
          header_row = {
            start: parseInt(header_row.split("-")[0]) - 1,
            end: parseInt(header_row.split("-")[-1]),
          };
        } else {
          header_row = {
            start: parseInt(header_row) - 1,
            end: parseInt(header_row),
          };
        }
      } catch {
        header_row = {
          start: 0,
          end: 1,
        };
      }
    }

    const fr = (header_row || {}).end ? header_row.end : 0;
    let first_row = config.first_row ? config.first_row - 1 : fr;

    if (first_row < (header_row || {}).end) {
      first_row = fr;
    }

    rows.forEach((row) => {
      if (header_row !== "-1") {
        if (!addHeader) {
          row.rawData.slice(header_row.start, header_row.end).forEach((d) => {
            combineData.push(d);
          });
          addHeader = true;
        } else if (config.include_2nd_onward_header) {
          row.rawData.slice(header_row.start, header_row.end).forEach((d) => {
            combineData.push(d);
          });
        }
      }

      row.rawData.slice(first_row).forEach((d) => {
        combineData.push(d);
      });
    });

    if (saveOptions.save_type === "csv") {
      saveCsvFromArr(combineData, saveOptions.save_filename);
    } else {
      alert(t("Something went wrong"));
    }

    gtag("event", "freetools_result");

    setIsDownload(false);
  };

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="center">
      <SuiBox mb={2}>
        <CdxInput
          label={t("File name")}
          value={saveOptions.save_filename}
          onChange={(e) => {
            setSaveOptionsKey(e.target.value, "save_filename");
          }}
        />
      </SuiBox>
      <SuiBox mb={2} display="flex" justifyContent="center">
        <CdxButton
          variant="gradient"
          color="info"
          disabled={rows.length <= 0}
          onClick={downloadMergeFile}
          progress={isDownload}
        >
          {t("Merge CSV")}
        </CdxButton>
      </SuiBox>
    </SuiBox>
  );
};

export default Merge;
