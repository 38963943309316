import { Fragment, useState, useEffect } from "react";

import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import MergePdfTable from "./table";

import { PDFDocument } from "pdf-lib";

import { useTranslation } from "react-i18next";

const File = ({ rows, setRows }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const summariseFiles = async (files) => {
    const newRows = [];
    let i = rows.length;
    files.forEach(async (e) => {
      newRows.push({
        idx: i,
        file: e,
        filename: e.name,
        extension: e.name.split(".").at(-1),
        isProcessing: true,
      });
      i += 1;
    });
    setRows([...rows, ...newRows]);
    setFiles([]);
  };

  useEffect(() => {
    if (files.length > 0) {
      summariseFiles(files);
    }
  }, [files]);

  const setNewRows = (currentRows, newRow, idx) => {
    let newRows = [...currentRows];
    newRows[idx] = newRow;
    newRows = newRows.sort((a, b) => a.idx - b.idx);

    let i = -1;
    newRows = newRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    setRows(newRows);
  };

  const processPdf = async (rows, idx) => {
    const file = rows[idx].file;
    let newRow = { ...rows[idx] };
    if (newRow.extension === "pdf") {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const pdfDoc = await PDFDocument.load(event.target.result);
          newRow = {
            ...rows[idx],
            pdf: pdfDoc,
            noOfPages: pdfDoc.getPageCount(),
            isProcessing: false,
          };
          setNewRows(rows, newRow, idx);
        } catch {
          newRow = {
            ...rows[idx],
            noOfPages: t("Cannot read PDF file"),
            isProcessing: false,
          };
          setNewRows(rows, newRow, idx);
        }
      };
      reader.readAsArrayBuffer(newRow.file);
    } else {
      newRow = {
        ...rows[idx],
        noOfPages: t("Invalid file type"),
        isProcessing: false,
      };
      setNewRows(rows, newRow, idx);
    }
  };

  useEffect(() => {
    if (rows.filter((e) => e.isProcessing).length > 0) {
      if (!isProcessing) {
        setIsProcessing(true);
      }
      const idx = rows.findIndex((e) => e.isProcessing);
      processPdf(rows, idx);
    } else {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  }, [rows]);

  return (
    <Fragment>
      {!isProcessing && (
        <Fragment>
          <CdxDropzoneUploadMultiple setFile={setFiles} />
          <SuiTypography variant="caption">
            {t(
              "Remark: All data is process locally. No data is uploaded to the server."
            )}
          </SuiTypography>
        </Fragment>
      )}
      {rows.length > 0 && (
        <SuiBox mt={2}>
          <MergePdfTable rows={rows} setRows={setRows} />
        </SuiBox>
      )}
    </Fragment>
  );
};

export default File;
