export const BASE_PATH = "";
export const BASE_URL = "https://codexlab.co";
export const BASE_APP_URL = "https://freetools.codexlab.co";
export const BASE_COOKIE_URL = ".codexlab.co";

// export const BASE_APP_URL = "https://codex-test-upload.web.app";
// export const BASE_COOKIE_URL = ".codex-test-upload.web.app";
// export const BASE_APP_URL = "http://localhost:3000";
// export const BASE_COOKIE_URL = "localhost";

export const URL_FREETOOLS = "https://freetools.codexlab.co";
export const URL_CODEXSHEET = "https://beta.sheet.codexlab.co";

export const PATH_TO_SKIP_PRIVACY_CONSENT = ["/privacy"];

export const rootElement = document.getElementById("root");
