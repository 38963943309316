import { useState } from "react";

import SuiBox from "components/sui/SuiBox";

import CdxButton from "components/cdx/CdxButton";

import { useTranslation } from "react-i18next";

import CdxInput from "components/cdx/CdxInput";

import gtag from "app/ga";

import * as XLSX from "xlsx/xlsx.mjs";

const Merge = ({ rows, saveOptions, setSaveOptions }) => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(false);

  const setSaveOptionsKey = (value, key) => {
    setSaveOptions({ ...saveOptions, [key]: value });
  };

  const downloadMergeFile = () => {
    setIsDownload(true);

    const workbook = XLSX.utils.book_new();
    const sheetNames = [];

    rows.forEach((row) => {
      row.sheetNames.forEach((sn) => {
        const worksheet = row.workbook.Sheets[sn];
        let cleanSaveName = sn;
        let duplicateName = true;
        if (
          sheetNames.filter((e) => {
            return e === cleanSaveName;
          }).length === 0
        ) {
          duplicateName = false;
        }

        let i = 0;
        while (duplicateName) {
          i += 1;
          const tempName = `${cleanSaveName} (${i})`;
          if (
            sheetNames.filter((e) => {
              return e === tempName;
            }).length === 0
          ) {
            cleanSaveName = tempName;
            duplicateName = false;
          }
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, cleanSaveName);
        sheetNames.push(cleanSaveName);
      });
    });

    if (saveOptions.save_type === "xlsx") {
      XLSX.writeFile(workbook, `${saveOptions.save_filename}.xlsx`);
    } else {
      alert(t("Something went wrong"));
    }

    gtag("event", "freetools_result");

    setIsDownload(false);
  };

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="center">
      <SuiBox mb={2}>
        <CdxInput
          label={t("File name")}
          value={saveOptions.save_filename}
          onChange={(e) => {
            setSaveOptionsKey(e.target.value, "save_filename");
          }}
        />
      </SuiBox>
      <SuiBox mb={2} display="flex" justifyContent="center">
        <CdxButton
          variant="gradient"
          color="info"
          disabled={rows.length <= 0}
          onClick={downloadMergeFile}
          progress={isDownload}
        >
          {t("Merge XLSX")}
        </CdxButton>
      </SuiBox>
    </SuiBox>
  );
};

export default Merge;
