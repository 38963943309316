import { useState } from "react";

import SuiBox from "components/sui/SuiBox";

import CdxButton from "components/cdx/CdxButton";

import { useTranslation } from "react-i18next";

import CdxInput from "components/cdx/CdxInput";

import gtag from "app/ga";

import * as XLSX from "xlsx/xlsx.mjs";

const Merge = ({ rows, config, saveOptions, setSaveOptions }) => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(false);

  const setSaveOptionsKey = (value, key) => {
    setSaveOptions({ ...saveOptions, [key]: value });
  };

  const downloadMergeFile = () => {
    setIsDownload(true);

    const workbook = XLSX.utils.book_new();

    let worksheet = null;
    let combine_data = [];

    const header_row = parseInt(config.header_row);

    if (header_row === -1) {
      rows.forEach((row) => {
        row.sheetNames.forEach((sn) => {
          if (!config.exclude_sheets.includes(sn)) {
            const ws = row.workbook.Sheets[sn];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            combine_data = [
              ...combine_data,
              ...data.filter((d) => d.length > 0),
            ];
          }
        });
      });
      worksheet = XLSX.utils.aoa_to_sheet(combine_data);
    } else {
      let headers = [];
      rows.forEach((row) => {
        row.sheetNames.forEach((sn) => {
          if (!config.exclude_sheets.includes(sn)) {
            const ws = row.workbook.Sheets[sn];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const local_headers = data.at(header_row - 1);
            headers = [...new Set([...headers, ...local_headers])];
            const json_data = data.slice(header_row).map((d) => {
              if (d.length > 0) {
                const payload = {};
                local_headers.forEach((h, idx) => {
                  payload[h] = d.at(idx);
                });
                return payload;
              } else {
                return null;
              }
            });
            combine_data = [...combine_data, ...json_data.filter((d) => d)];
          }
        });
      });

      worksheet = XLSX.utils.json_to_sheet(combine_data, {
        header: headers,
      });
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, "merge-sheet");

    if (saveOptions.save_type === "xlsx") {
      XLSX.writeFile(workbook, `${saveOptions.save_filename}.xlsx`);
    } else {
      alert(t("Something went wrong"));
    }

    gtag("event", "freetools_result");

    setIsDownload(false);
  };

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="center">
      <SuiBox mb={2}>
        <CdxInput
          label={t("File name")}
          value={saveOptions.save_filename}
          onChange={(e) => {
            setSaveOptionsKey(e.target.value, "save_filename");
          }}
        />
      </SuiBox>
      <SuiBox mb={2} display="flex" justifyContent="center">
        <CdxButton
          variant="gradient"
          color="info"
          disabled={rows.length <= 0}
          onClick={downloadMergeFile}
          progress={isDownload}
        >
          {t("Merge Sheets")}
        </CdxButton>
      </SuiBox>
    </SuiBox>
  );
};

export default Merge;
