import { useState } from "react";

import SuiBox from "components/sui/SuiBox";

import CdxButton from "components/cdx/CdxButton";

import { useTranslation } from "react-i18next";

import CdxInput from "components/cdx/CdxInput";

import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import savePdfFromByteArr from "app/freetools/savePdfFromByteArr";

import gtag from "app/ga";
import bahttext from "app/utils/bahttext";
import excelDateToISO from "app/utils/excelDateToISO";

const Generate = ({ rows, config, saveOptions, setSaveOptions }) => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(false);

  const setSaveOptionsKey = (value, key) => {
    setSaveOptions({ ...saveOptions, [key]: value });
  };

  const _form = {
    "ภ.ง.ด. 1ก": "chk1",
    "ภ.ง.ด. 1ก พิเศษ": "chk2",
    "ภ.ง.ด. 2": "chk3",
    "ภ.ง.ด. 3": "chk4",
    "ภ.ง.ด. 2ก": "chk5",
    "ภ.ง.ด. 3ก": "chk6",
    "ภ.ง.ด. 53": "chk7",
  };

  const _type = {
    "ภาษีหัก ณ ที่จ่าย": "chk8",
    "หัก ณ ที่จ่าย": "chk8",
    ออกภาษีให้ตลอดไป: "chk9",
    ออกให้ตลอดไป: "chk9",
    ออกภาษีให้ครั้งเดียว: "chk10",
    ออกให้ครั้งเดียว: "chk10",
  };

  const _line = {
    1: ["pay1.0", "tax1.0", "date1"],
    2: ["pay1.1", "tax1.1", "date2"],
    3: ["pay1.2", "tax1.2", "date3"],
    "4ก": ["pay1.3", "tax1.3", "date4"],
    "4ข1.1": ["pay1.4", "tax1.4", "date5"],
    "4ข1.2": ["pay1.5", "tax1.5", "date6"],
    "4ข1.3": ["pay1.6", "tax1.6", "date7"],
    "4ข1.4": ["pay1.7", "tax1.7", "date8", "rate1"],
    "4ข2.1": ["pay1.8", "tax1.8", "date9"],
    "4ข2.2": ["pay1.9", "tax1.9", "date10"],
    "4ข2.3": ["pay1.10", "tax1.10", "date11"],
    "4ข2.4": ["pay1.11", "tax1.11", "date12"],
    "4ข2.5": ["pay1.12", "tax1.12", "date13", "spec1"],
    5: ["pay1.13.0", "tax1.13.0", "date14.0"],
    6: ["pay1.13.1", "tax1.13.1", "date14.1", "spec3"],
  };

  const n2s = (n) => {
    return n.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderPdfBytes = async (existingPdfBytes, row, fontBytes) => {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const form = pdfDoc.getForm();
    const fields = form.getFields();

    const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
    form.updateFieldAppearances = function () {
      return rawUpdateFieldAppearances(customFont);
    };

    fields.forEach((field) => {
      try {
        field.defaultUpdateAppearances(customFont);
        if (["book_no", "run_no"].includes(field.getName())) {
          field.setFontSize(10);
        } else {
          field.setFontSize(13);
        }
      } catch {}
    });

    form.getField("book_no").setText(`${row.book_no}` || "");
    form.getField("run_no").setText(`${row.doc_no}` || "");
    form.getField("item").setText(`${row.seq_no}`.padStart(5, " ") || "");
    form.getField("name1").setText(`${config.isn}` || "");
    form.getField("add1").setText(`${config.isa}` || "");

    if (config.isi.length === 10) {
      form
        .getField("tin1")
        .setText(
          `${config.isi.slice(0, 1)} ${config.isi.slice(
            1,
            5
          )} ${config.isi.slice(5, 9)} ${config.isi.slice(9, 10)}` || ""
        );
    } else {
      form
        .getField("id1")
        .setText(
          `${config.isi.slice(0, 1)} ${config.isi.slice(
            1,
            5
          )} ${config.isi.slice(5, 10)} ${config.isi.slice(
            10,
            12
          )} ${config.isi.slice(12, 13)}` || ""
        );
    }

    form.getField("name2").setText(`${row.name}` || "");
    form.getField("add2").setText(`${row.address}` || "");

    const _id = `${row.id}`;
    if (_id.length === 10) {
      form
        .getField("tin1_2")
        .setText(
          `${_id.slice(0, 1)} ${_id.slice(1, 5)} ${_id.slice(5, 9)} ${_id.slice(
            9,
            10
          )}` || ""
        );
    } else {
      form
        .getField("id1_2")
        .setText(
          `${_id.slice(0, 1)} ${_id.slice(1, 5)} ${_id.slice(
            5,
            10
          )} ${_id.slice(10, 12)} ${_id.slice(12, 13)}` || ""
        );
    }

    let l = _line[6];
    if (_line[row.pay_type]) {
      l = _line[row.pay_type];
    }

    form.getField(l.at(0)).setText(`${n2s(parseFloat(row.amount))} ` || "");
    form.getField("pay1.14").setText(`${n2s(parseFloat(row.amount))} ` || "");

    form.getField(l.at(1)).setText(`${n2s(parseFloat(row.wht))} ` || "");
    form.getField("tax1.14").setText(`${n2s(parseFloat(row.wht))} ` || "");

    let pay_date = "";
    if (typeof row.payment_date === "number") {
      const [_y, _m, _d] = excelDateToISO(row.payment_date).split("-");
      pay_date = `${_d}/${_m}/${parseInt(_y) + 543}`;
    } else {
      pay_date = row.payment_date;
    }

    form.getField(l.at(2)).setText(`${pay_date}` || "");

    if (l.at(3)) {
      form.getField(l.at(3)).setText(`${row.remark}` || "");
    }

    form.getField("total").setText(bahttext(parseFloat(row.wht) || 0));

    if (row.gpf) {
      form.getField("Text1.0.0").setText(`${n2s(parseFloat(row.gpf))}` || "");
    }
    if (row.social_security) {
      form
        .getField("Text1.0.1")
        .setText(`${n2s(parseFloat(row.social_security))}` || "");
    }
    if (row.pvf) {
      form.getField("Text1.1.0").setText(`${n2s(parseFloat(row.pvf))}` || "");
    }

    try {
      const [_y, _m, _d] = excelDateToISO(row.doc_date).split("-");
      form.getField("date_pay").setText(`${_d}` || "");
      form.getField("month_pay").setText(`     ${_m}` || "");
      form.getField("year_pay").setText(`${parseInt(_y) + 543}` || "");
    } catch {}

    if (_form[row.doc_type]) {
      form.getField(_form[row.doc_type]).check();
    }

    if (row.wht_type) {
      if (_type[row.wht_type]) {
        form.getField(_type[row.wht_type]).check();
      } else {
        form.getField("chk11").check();
        form.getField("spec4").setText(`${row.wht_type}` || "");
      }
    }

    form.removeField(form.getField("clear data"));

    form.flatten();
    pdfDoc.setSubject("Generated from Codex Freetools");
    pdfDoc.setTitle("Generated from Codex Freetools");
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };

  const generate50Tawi = async () => {
    setIsDownload(true);

    let url = "/assets/tax/50Tawi/template.pdf";
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    url = "/assets/font/THSarabun.ttf";
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];
      const pdfBytes = await renderPdfBytes(existingPdfBytes, row, fontBytes);

      savePdfFromByteArr(
        pdfBytes,
        `${saveOptions.save_filename}_${row.book_no}_${row.doc_no}`
      );
    }

    gtag("event", "freetools_result");

    setIsDownload(false);
  };

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="center">
      <SuiBox mb={2}>
        <CdxInput
          label={t("File name prefix")}
          value={saveOptions.save_filename}
          onChange={(e) => {
            setSaveOptionsKey(e.target.value, "save_filename");
          }}
        />
      </SuiBox>
      <SuiBox mb={2} display="flex" justifyContent="center">
        <CdxButton
          variant="gradient"
          color="info"
          disabled={rows.length <= 0}
          onClick={generate50Tawi}
          progress={isDownload}
        >
          {t("Generate 50 Tawi")}
        </CdxButton>
      </SuiBox>
    </SuiBox>
  );
};

export default Generate;
