import { useState } from "react";

import SuiBox from "components/sui/SuiBox";

import CdxButton from "components/cdx/CdxButton";

import { useTranslation } from "react-i18next";

import CdxInput from "components/cdx/CdxInput";

import { PDFDocument } from "pdf-lib";

import savePdfFromByteArr from "app/freetools/savePdfFromByteArr";

import gtag from "app/ga";

const Merge = ({ rows, saveOptions, setSaveOptions }) => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(false);

  const setSaveOptionsKey = (value, key) => {
    setSaveOptions({ ...saveOptions, [key]: value });
  };

  const downloadMergeFile = async () => {
    setIsDownload(true);

    const pdfDoc = await PDFDocument.create();

    for (let r = 0; r < rows.length; r++) {
      const pdf = rows[r].pdf;
      for (let i = 0; i < rows[r].noOfPages; i++) {
        const [copiedPage] = await pdfDoc.copyPages(pdf, [i]);
        pdfDoc.addPage(copiedPage);
      }
    }

    if (saveOptions.save_type === "pdf") {
      const pdfBytes = await pdfDoc.save();
      savePdfFromByteArr(pdfBytes, saveOptions.save_filename);
    } else {
      alert(t("Something went wrong"));
    }

    gtag("event", "freetools_result");

    setIsDownload(false);
  };

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="center">
      <SuiBox mb={2}>
        <CdxInput
          label={t("File name")}
          value={saveOptions.save_filename}
          onChange={(e) => {
            setSaveOptionsKey(e.target.value, "save_filename");
          }}
        />
      </SuiBox>
      <SuiBox mb={2} display="flex" justifyContent="center">
        <CdxButton
          variant="gradient"
          color="info"
          disabled={rows.length <= 0}
          onClick={downloadMergeFile}
          progress={isDownload}
        >
          {t("Merge PDF")}
        </CdxButton>
      </SuiBox>
    </SuiBox>
  );
};

export default Merge;
