import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import CdxInput from "components/cdx/CdxInput";
import { useTranslation } from "react-i18next";
import CdxSwitch from "components/cdx/CdxSwitch";

const Setting = ({ config, setConfig }) => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");

  const setConfigKey = (value, key) => {
    setConfig({ ...config, [key]: value });
  };

  useEffect(() => {
    let header_row = `${config.header_row}`;
    if (header_row !== "-1") {
      try {
        if (header_row.search("-") > 0) {
          header_row = {
            start: parseInt(header_row.split("-")[0]) - 1,
            end: parseInt(header_row.split("-")[-1]),
          };
        } else {
          header_row = {
            start: parseInt(header_row) - 1,
            end: parseInt(header_row),
          };
        }
      } catch {
        header_row = {
          start: 0,
          end: 1,
        };
      }
    }

    const fr = header_row !== "-1" ? header_row.end : 0;
    let first_row = config.first_row ? config.first_row : fr;

    if (config.first_row && first_row < (header_row || {}).end) {
      setErrorMessage(t("First row must be after last header rows"));
    } else {
      setErrorMessage();
    }
  }, [config]);

  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item xs>
          <CdxInput
            label={t("Header rows")}
            value={config.header_row}
            onChange={(e) => {
              setConfigKey(e.target.value, "header_row");
            }}
            message={t(
              "If there is no header, please type -1. For multiple rows, please type a-b (e.g. 1-5)."
            )}
          />
        </Grid>
        <Grid item xs>
          <CdxInput
            label={t("First row start at")}
            value={config.first_row || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "first_row");
            }}
            error={!!errorMessage}
            message={
              errorMessage ||
              t("Leave blank to start right after the end of the header.")
            }
          />
        </Grid>
        <Grid item xs>
          <CdxSwitch
            label={[t("Repeat header"), t("Skip header")]}
            value={config.include_2nd_onward_header}
            onChange={() => {
              setConfigKey(
                !config.include_2nd_onward_header,
                "include_2nd_onward_header"
              );
            }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default Setting;
