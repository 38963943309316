import { useTranslation } from "react-i18next";

import { Icon } from "@mui/material";
import { Grid } from "@mui/material";

import CdxDataTable from "components/cdx/CdxDataTable";

const MergeXlsxTable = ({ rows, setRows }) => {
  const { t } = useTranslation();

  const sortRowByIdx = (r) => {
    let sortedRows = r.sort((a, b) => a.idx - b.idx);
    let i = -1;

    sortedRows = sortedRows.map((e) => {
      i += 1;
      return { ...e, idx: i };
    });

    return sortedRows;
  };

  const download = (idx) => {
    const file = rows[idx].file;
    const downloadBlob = new Blob([file]);

    const downloadUrl = window.URL.createObjectURL(downloadBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const deleteRow = (idx) => {
    let newRows = rows.filter((e, id) => id !== idx);
    newRows = sortRowByIdx(newRows);
    setRows(newRows);
  };

  const moveUp = (idx) => {
    let newRows = rows.map((e) => {
      return { ...e, idx: idx === e.idx ? e.idx - 1.5 : e.idx };
    });
    newRows = sortRowByIdx([...newRows]);
    setRows(newRows);
  };
  const moveDown = (idx) => {
    let newRows = rows.map((e) => {
      return { ...e, idx: idx === e.idx ? e.idx + 1.5 : e.idx };
    });
    newRows = sortRowByIdx([...newRows]);
    setRows(newRows);
  };

  return (
    <CdxDataTable
      entriesPerPage={{
        defaultValue: -1,
        entries: [{ value: -1, label: t("Show all") }],
      }}
      showSelectEntriesPerPage={false}
      showTotalEntries={false}
      table={{
        columns: [
          {
            Header: t("File name"),
            accessor: "filename",
          },
          {
            Header: t("Extension"),
            accessor: "extension",
          },
          {
            Header: t("No of sheet"),
            accessor: "noOfSheet",
          },
          {
            Header: t("Action"),
            accessor: "idx",
            Cell: ({ value }) => {
              return (
                <Grid container height="100%" spacing={2}>
                  <Grid
                    item
                    onClick={() => {
                      download(value);
                    }}
                  >
                    <Icon color="dark" sx={{ cursor: "pointer" }}>
                      download
                    </Icon>
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      deleteRow(value);
                    }}
                  >
                    <Icon color="error" sx={{ cursor: "pointer" }}>
                      delete
                    </Icon>
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      if (value === 0) {
                        return;
                      }
                      moveUp(value);
                    }}
                  >
                    <Icon color="success" sx={{ cursor: "pointer" }}>
                      arrow_upward
                    </Icon>
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      if (value === rows.length) {
                        return;
                      }
                      moveDown(value);
                    }}
                  >
                    <Icon color="warning" sx={{ cursor: "pointer" }}>
                      arrow_downward
                    </Icon>
                  </Grid>
                </Grid>
              );
            },
          },
        ],
        rows: rows,
      }}
    />
  );
};

export default MergeXlsxTable;
