import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import CdxInput from "components/cdx/CdxInput";
import CdxSwitch from "components/cdx/CdxSwitch";
import { useTranslation } from "react-i18next";

const Setting = ({ config, setConfig }) => {
  const { t } = useTranslation();

  const setConfigKey = (value, key) => {
    setConfig({ ...config, [key]: value });
  };

  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          <CdxInput
            type="number"
            label={t("First row start at")}
            value={config.first_row || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "first_row");
            }}
            message={t("Leave blank for data start at 2nd row")}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <CdxInput
            label={t("Columns index")}
            value={config.columns || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "columns");
            }}
            message={t("For multiple column, please use comma “,”")}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <CdxInput
            label={t("Delimiter")}
            value={config.delimiter || ""}
            onChange={(e) => {
              setConfigKey(e.target.value, "delimiter");
            }}
            message={t("Leave blank for comma delimiter “,”")}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <CdxSwitch
            label={[t("Include original name"), t("Only split value")]}
            value={config.incl_original}
            onChange={() => {
              setConfigKey(!config.incl_original, "incl_original");
            }}
            spaceLabelAtTop={{ xs: false, md: true }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default Setting;
